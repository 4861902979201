import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { ThemeModeService } from './_layout/components/theme-mode-switcher/theme-mode.service';
import rg4js from 'raygun4js';

@Component({
    selector: 'body[root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {

  constructor(
    private readonly _modeService: ThemeModeService,
    private readonly _router: Router
  ) { }

  private _bindRaygun() {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        rg4js('trackEvent', { type: 'pageView', path: event.url });
      }

      if (event instanceof NavigationError) {
        rg4js('send', { error: event.error });
      }
    });
  }

  ngOnInit() {
    this._bindRaygun();
    this._modeService.init();
  }

}
